export const MAILING_LIST_PREFIX = "mailing.list";

export enum BackofficeConfigurationKey {
  PASSWORD_RESET_REDIRECT_URL = "email.passwordreset.redirecturl",
  VERIFICATION_REDIRECT_URL = "email.verification.redirecturl",
  USER_PROFILE_SCHEMA = "user.profile.schema",
  // Do not allow any new users to be created within the respective project
  USER_PROJECT_NO_CREATION = "user.project.nocreation",
  // Indicates if a an access key for unsplash.com is set or not. This config key is set automatically
  SEARCH_UNSPLASH = "files.search.unsplash",
  MAILING_LIST_REPLIES_MAIL_ADDRESS = "mailing.list.replies.mail.address",
  MAILING_LIST_OPT_IN_CONFIRM_MAIL_SUBJECT = "mailing.list.optin.confirmation.mail.subject",
  MAILING_LIST_OPT_IN_CONFIRM_MAIL_BODY = "mailing.list.optin.confirmation.mail.body",
  MAILING_LIST_OPT_OUT_CONFIRM_MAIL_SUBJECT = "mailing.list.optout.confirmation.mail.subject",
  MAILING_LIST_OPT_OUT_CONFIRM_MAIL_BODY = "mailing.list.optout.confirmation.mail.body",
  MAILING_LIST_OPT_IN_CONFIRM_SMS_BODY = "mailing.list.optin.confirmation.sms.body",
  MAILING_LIST_OPT_OUT_CONFIRM_SMS_BODY = "mailing.list.optout.confirmation.sms.body",
  // Calendar start and end time configurations
  CALENDAR_STARTTIME = "calendar.ui.starttime",
  CALENDAR_ENDTIME = "calendar.ui.endtime",
  /**
   * If set, given LinkedModelRepresentations will be added to the LinkedModelService.
   * Expected value: [{icon: "car", type: "custom/cars", label: "Auto", property: "name"}]
   */
  LINKED_MODEL_REPRESENTATIONS = "links.models.representations"
}
